.RemediationsPage .MultiFilterBox {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: stretch;
  row-gap: 1rem;
  column-gap: 1rem;
  margin: 1rem auto;
}
.RemediationsPage .MultiFilterBox > * {
  flex-basis: 100%;
  padding: 0;
  margin: 0;
}
@media (min-width: 768px) {
  .RemediationsPage .MultiFilterBox {
    justify-content: flex-start;
  }
  .RemediationsPage .MultiFilterBox > * {
    flex-basis: calc((99.99% - 2rem) / 3);
  }
  .RemediationsPage .MultiFilterBox > *.canExpand {
    flex-grow: 2;
  }
}

