@import "vars";

.PieDomande-s {
	display: inline-block;
	width: 90px;
	height: 90px;
	overflow: hidden;
	position: relative;
	> div {
		width: 120px;
		height: 120px;
		position: absolute;
		top: -15px;
		left: -15px;
		svg {
			* {
				pointer-events: none;
			}
			text {
				display: none;
			}
		}
	}
}

.PieDomande:not(.PieDomande-s) {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	align-content: stretch;
	justify-content: stretch;
	row-gap: 1rem;
	column-gap: 1rem;
	svg text {
		pointer-events: none;
	}
	> div {
		flex-basis: 100%;
		margin: 0;
		flex-grow: 1;
		position: relative;
		overflow: hidden;
		> title {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: $darkColor;
			font-weight: bold;
		}
	}
	&.PieDomande-m {
		> div {
			height: 160px;
		}
	}
	&.PieDomande-l {
		> div {
			height: 240px;
		}
	}
	&.PieDomande-xl {
		> div {
			height: 480px;
		}
	}
	@media (min-width: 768px) {
		&.PieDomande-m {
			> div {
				flex-basis: 320px;
			}
		}
		&.PieDomande-l {
			> div {
				flex-basis: 480px;
			}
		}
		&.PieDomande-xl {
			> div {
				flex-basis: 100%;
			}
		}
	}
}
