.ConfrontoPage .topControls {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: stretch;
  row-gap: 1rem;
  column-gap: 1rem;
  margin: 1rem 0;
}
.ConfrontoPage .topControls > * {
  flex-basis: 100%;
  padding: 0;
  margin: 0;
}
@media (min-width: 768px) {
  .ConfrontoPage .topControls {
    justify-content: flex-start;
  }
  .ConfrontoPage .topControls > * {
    flex-basis: calc((99.99% - 1rem) / 2);
  }
  .ConfrontoPage .topControls > *.canExpand {
    flex-grow: 2;
  }
}

