.SvgPie {
	position: relative;
	display: inline-block;
	padding: 0;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	> svg {
		position:absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&.SvgProgress {
		> .SvgLabel {
			position: absolute;
			left: 0;
			right: 0;
			line-height: 12px;
			font-size: 14px;
			top: calc(50% - 7px);
			font-weight: bold;
			color: #fff;
			text-shadow: -1px 0 2px #00000066, 2px 0 2px #00000066, 0 -1px 2px #00000066, 0 2px 2px #00000066;
			vertical-align: middle;
		}
		&.Masked {
			> .SvgLabel {
				color: #000;
				transform: scale(.9);
				font-weight: normal;
				text-shadow: none;
			}
		}
		&.SizeS {
			> .SvgLabel {
				font-size: 12px;
			}
		}
		&.SizeXS {
			> .SvgLabel {
				display: none;
			}
		}
	}
}
.SvgBulbs {
	position: relative;
	display: inline-block;
	padding: 0;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: visible;
	> svg {
		display: block;
		width: 100%;
		margin: 0;
	}
	&.SvgProgress {
		> .SvgLabel {
			width: 100%;
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 88%;
		}
	}
}