.PieDomande-s {
  display: inline-block;
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: relative;
}
.PieDomande-s > div {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -15px;
  left: -15px;
}
.PieDomande-s > div svg * {
  pointer-events: none;
}
.PieDomande-s > div svg text {
  display: none;
}

.PieDomande:not(.PieDomande-s) {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  row-gap: 1rem;
  column-gap: 1rem;
}
.PieDomande:not(.PieDomande-s) svg text {
  pointer-events: none;
}
.PieDomande:not(.PieDomande-s) > div {
  flex-basis: 100%;
  margin: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}
.PieDomande:not(.PieDomande-s) > div > title {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #033661;
  font-weight: bold;
}
.PieDomande:not(.PieDomande-s).PieDomande-m > div {
  height: 160px;
}
.PieDomande:not(.PieDomande-s).PieDomande-l > div {
  height: 240px;
}
.PieDomande:not(.PieDomande-s).PieDomande-xl > div {
  height: 480px;
}
@media (min-width: 768px) {
  .PieDomande:not(.PieDomande-s).PieDomande-m > div {
    flex-basis: 320px;
  }
  .PieDomande:not(.PieDomande-s).PieDomande-l > div {
    flex-basis: 480px;
  }
  .PieDomande:not(.PieDomande-s).PieDomande-xl > div {
    flex-basis: 100%;
  }
}

