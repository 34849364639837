$dangerColor: #a80303;
$lowColor: #ff7300;
$mediumColor: #febf00;
$highColor: #82cb2a;
$maxColor: #009000;

$bodyColor: #fff;

$lighestColor: #f6f6f6;
$darkColor: #033661;
$menuColor: #fff;
$menuHighlight: #ff0;
$submenuBorderColor: #ffffff66;

@mixin FlexTable($cols: 3, $rowgap: 1rem, $colgap: 1rem) {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: stretch;
	row-gap: $rowgap;
	column-gap: $colgap;
	> * {
		flex-basis: 100%;
		padding: 0;
		margin: 0;
	}
	@media (min-width: 768px) {
		justify-content: flex-start;
		> * {
			flex-basis: calc( ( 99.99% - $colgap * ( $cols - 1) ) / $cols );
			&.canExpand {
				flex-grow: 2;
			}
		}
	}
}