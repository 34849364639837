@import "vars";

.darkColor {
	color: $darkColor;
	fill: $darkColor;
}

div.titoloSezione {
	> p {
		display: flex;
		margin-bottom: 24px;
		line-height: 28px;
		font-size: 20px;
		color : $darkColor;
		&::after {
			display: block;
			content: " ";
			height: 4px;
			border: none;
			background-image: linear-gradient(270deg, rgb(67 67 67 / 60%), $darkColor);
			flex: 1 1 auto;
			margin-left: 10px;
			margin-top: 1rem;
		}
	}
}

div.titoloSezioneCenter {
	> p {
		display: flex;
		margin-bottom: 24px;
		line-height: 20px;
		&::before, &::after {
			display: block;
			content: " ";
			border-style: solid;
			flex: 1 1 auto;
			margin-top: 5px;
		}
		&::before {
			border-image: linear-gradient(270deg, rgb(67 67 67 / 60%), $darkColor) 1;
			margin-right: 10px;
		}
		&::after {
			border-image: linear-gradient(90deg, rgb(67 67 67 / 60%), $darkColor) 1;
			margin-left: 10px;
		}
	}
}

div.breadCrumbs {
	position: absolute;
	top: 0; left: 0;
	right: 0;
	margin: 0;
	padding: 0 0 0 .5rem;
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	column-gap: .25rem;
	/* border-bottom: solid 1px $darkColor; */
	background-color: #ccc;
	background-image: linear-gradient(to top, #ccc,#ddd 1%,#eee 60%,#f6f6f6,#ddd 99%,#ccc);
	a.link, * {
		color: #666;
		fill: #666;
		font-size: 13px;
	}
	p {
		flex-shrink: 2;
		margin: 0;
		padding: 0;
		white-space: pre;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

div.typeStrategy {
	> p {
		display: flex;
		margin-bottom: 24px;
		margin-top: 16px;
		opacity: 0.8;
		margin-left: 8px;
	}
}

.link:hover {
	text-decoration: underline !important;
	color: $darkColor;
}

.cursorPoint {
	cursor: pointer !important;
}

.progress-bar-striped {
	background-image: linear-gradient(
			45deg,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
	);
}

.ps__rail-y {
	background-color: transparent !important;
	width: 6px !important;
	&:hover {
		background-color: transparent !important;
		width: 6px !important;
		> .ps__thumb-y {
			background-color: #999;
			width: 6px !important;
		}
	}
}

.ps__thumb-y {
	right: 0 !important;
}

.buttonUpload {
	height: 53.5px !important;
	margin-top: 8px !important;
	vertical-align: middle !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0px 0px 6px 6px rgb(36 93 139 / 44%);
}

*::-webkit-scrollbar-thumb {
	border-radius: 1px;
	-webkit-box-shadow: inset 0px 1px 6px 2px rgb(1 27 48);
}

*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.go-edit {
	display: inline-block;
	position: relative;
	transition: all 0.25s ease-out;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 1px;
		bottom: 0;
		left: 0;
		background-color: #0087ca;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	&:hover {
		color: #0087ca;
		transition: all 0.25s ease-out;
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

label.boxTitle, .boxField > label {
	color: #033361;
	background-color: #fff;
	padding: 0 .25rem;
	white-space: nowrap;
}
.boxField > .MuiOutlinedInput-input, .boxField > .MuiOutlinedInput-root {
	min-height: 48px !important;
}

.progress.smallBar {
	width: 120px;
}

.SvgProgressExternal {
	fill: #eee;
}
.SvgProgressInternal {
	fill: $darkColor;
}

@mixin weighted( $color : "#ccc", $labelColor: $darkColor) {
	color: $color;
	fill: $color;
	&.progress > .progress-bar {
		background-color: $color;
	}
	.SvgProgressExternal {
		stroke: $color;
		fill: #e6e6e6;
	}
	.SvgProgressInternal.Off {
		stroke: transparent;
		opacity: .15;
	}
	.SvgProgressInternal {
		fill: $color;
	}
	&.SvgProgress.Masked, .SvgProgress.Masked {
		svg + .SvgLabel {
			fill: $labelColor;
			color: $labelColor;
		}
	}
	&.SvgBulbs, .SvgBulbs {
		svg {
			overflow: visible;
		}
		.SvgProgressInternal {
			stroke: transparent;
		}
		.SvgLabel {
			color: $labelColor;
			fill: $labelColor;
		}
		.SvgProgressExternal {
			stroke: $color;
			fill: #fff;
			filter: drop-shadow(1px 1px 1px #00000033);
		}
	}
}


*[class*="weighted"] {
	color: #ccc;
	&.progress {
		box-shadow: inset 0 0 4px #00000044;
		border-radius: 12px;
		height: 12px;
		> .progress-bar {
			background-image: linear-gradient(to top, #00000044,#00000000,#00000000,#00000044);
			height: 12px;
		}
	}
	&.weighted-unk {
		@include weighted("#ccc");
	}
	&.weightedBas, &.weighted-max {
		@include weighted($maxColor);
	}
	&.weighted-high {
		@include weighted($highColor);
	}
	&.weightedMed, &.weighted-medium {
		@include weighted($mediumColor);
	}
	&.weighted-low {
		@include weighted($lowColor);
	}
	&.weightedEle, &.weighted-danger {
		@include weighted($dangerColor,$dangerColor);
		&.SvgBulbs, .SvgBulbs {
			.SvgLabel {
				font-weight: bold;
			}
		}
	}
}

.modalDialog {
	.modalTitle {
		border-radius: 4px 4px 0 0;
		padding: 4px 1rem;
		background-color: $darkColor;
		color: #fff;
		> svg:first-child {
			opacity: .75;
			margin: -4px 8px 0 -8px;
			fill: #ffcd39;
		}
		button {
			position: absolute;
			top: 0;
			right: .25rem;
			svg {
				fill: #fff;
			}
		}
	}
	.modalBody {
		padding: .5rem;
		.modalRow {
			padding-top: .5rem;
			display: flex;
			flex-direction: row;
			gap: .5rem;
			align-items: flex-end;
			align-content: space-between;
			flex-wrap: nowrap;
			.modalRowText {
				flex-grow: 1;
				padding: .5rem;
				.question {
					font-weight: bold;
				}
				.answer {
					font-style: italic;
				}
			}
			.modalRowControls {
				padding: .5rem;
				.control {
					width: 10rem;
					label {
						display: inline-block;
						font-weight: bold;
						font-style: italic;
						color: #999;
						width: 5rem;
						text-align: right;
					}
					span {
						display: inline-block;
						margin-left: .5rem;
						font-weight: bold;
					}
				}
			}
			&:first-of-type:last-of-type {
				margin: 1rem;
				padding: 0;
			}
			+ .modalRow {
				margin-top: .5rem;
				border-top: 1px solid #ccc;
			}
			*[class*="weighted"] {
				color: #ccc;
				&.progress {
					box-shadow: inset 0 0 4px #00000044;
					border-radius: 12px;
					height: 12px;
					> .progress-bar {
						background-image: linear-gradient(to top, #00000044,#00000000,#00000000,#00000044);
						height: 12px;
					}
				}
				&.weighted-unk {
					color: #ccc;
					&.progress > .progress-bar {
						background-color: #ccc;
					}
				}
				&.weightedBas, &.weighted-max {
					color: $maxColor;
					&.progress > .progress-bar {
						background-color: $maxColor;
					}
				}
				&.weightedMed, &.weighted-medium {
					color: $mediumColor;
					&.progress > .progress-bar {
						background-color: $mediumColor;
					}
				}
				&.weighted-high {
					color: $highColor;
					&.progress > .progress-bar {
						background-color: $highColor;
					}
				}
				&.weighted-low {
					color: $lowColor;
					&.progress > .progress-bar {
						background-color: $lowColor;
					}
				}
				&.weightedEle, &.weighted-danger {
					color: $dangerColor;
					&.progress > .progress-bar {
						background-color: $dangerColor;
					}
				}
			}
		}
	}
}

.MuiSelect-select
.MuiSelect-outlined
.MuiOutlinedInput-input
.MuiInputBase-input {
}
