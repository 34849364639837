@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");
body[theme] {
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body[theme] * {
  box-sizing: border-box;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
body[theme] .SiteHeader {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
}
body[theme] .SiteHeader .colorProgressBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-image: linear-gradient(to right, #a80303, #ff7300, #febf00, #82cb2a, #009000);
  z-index: 40;
}
body[theme] .SiteHeader #appBar {
  position: fixed;
  background-color: #f6f6f6;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 88px;
  border-bottom: solid 1px #033661;
}
body[theme] .SiteHeader #appBar .siteLogo {
  height: 52px;
  width: 120px;
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: contain;
}
body[theme] .SiteHeader #appBar .rightPortion {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  flex-grow: 0;
}
body[theme] .SiteHeader .leftDrawer {
  width: 350px;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  display: flex;
  flex-shrink: 0;
  background-color: #033661;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar {
  margin-top: 2.3rem;
  padding: 0;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root {
  position: relative;
  margin: 0 0 0 16px;
  width: auto;
  column-gap: 8px;
  align-items: flex-start;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .SvgProgressExternal {
  stroke: #ccc;
  fill: transparent;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .SvgProgressInternal {
  fill: #ccc;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .MuiTypography-root {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root.Mui-selected {
  background-color: transparent;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root.Mui-selected > * > .MuiTypography-root {
  color: #ff0;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root.Mui-selected::before {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #ff0;
  border-radius: 6px;
  position: absolute;
  left: -4px;
  top: calc(50% - 6px);
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .chevronIcon {
  display: block;
  position: absolute;
  left: -8px;
  top: calc(50% - 12px);
  fill: #fff;
  transition: all 0.4s ease;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .chevronIcon.opened {
  transform: rotateZ(0);
  fill: #ff0;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiListItem-root .chevronIcon.closed {
  transform: rotateZ(-90deg);
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar .MuiCollapse-wrapper.MuiCollapse-vertical {
  background-color: transparent;
  padding: 0 0 0 0;
  margin: 0 0 0 19px;
  border-left: solid 1px rgba(255, 255, 255, 0.4);
  width: auto;
}
body[theme] .SiteHeader .leftDrawer .MuiDrawer-paper .custom-scrollbar hr.MuiDivider-root {
  display: none;
  margin: 0 8px !important;
  padding: 0 !important;
  border-color: #fff;
}
body[theme] .SiteHeader main {
  display: block;
  margin-top: 88px;
  min-height: calc(100vh - 88px);
  padding: 0 0 0 0;
  flex-grow: 1;
  background-color: #fff;
  max-width: 100%;
}
body[theme] .SiteHeader main .mainContent {
  min-height: calc(100vh - 88px);
  width: 100%;
  position: relative;
  padding: 1rem 0 0 0;
}
body[theme] .SiteHeader main .mainContent > * {
  max-width: 100%;
}

body[theme=DP] .SiteHeader #appBar .siteLogo {
  background-image: url("../img/dp-platform.png");
}

body[theme=HB] .SiteHeader #appBar .siteLogo {
  background-image: url("../img/HB.png");
}

