@charset "utf-8";
@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body[theme] {
	font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	* {
		box-sizing: border-box;
		font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	}
	.SiteHeader {
		margin: 0;
		padding: 0;
		width: 100%;
		position: relative;
		.colorProgressBar {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 4px;
			background-image: linear-gradient(to right,$dangerColor,$lowColor,$mediumColor,$highColor,$maxColor);
			z-index: 40;
		}
		#appBar {
			position: fixed;
			background-color: $lighestColor;
			box-shadow: none;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			height: 88px;
			border-bottom: solid 1px $darkColor;
			.siteLogo {
				height: 52px;
				width: 120px;
				background-repeat: no-repeat;
				background-position-x: left;
				background-size: contain;
			}
			.rightPortion {
				display: flex;
				justify-content: center;
				margin-top: 5px;
				flex-grow: 0;
			}
		}
		.leftDrawer {
			width: 350px;
			.MuiDrawer-paper {
				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				width: 350px;
				display: flex;
				flex-shrink: 0;
				background-color: $darkColor;
				.custom-scrollbar {
					margin-top: 2.3rem;
					padding: 0;
					.MuiListItem-root {
						position: relative;
						margin: 0 0 0 16px;
						width: auto;
						column-gap: 8px;
						align-items: flex-start;
						.SvgProgressExternal {
							stroke: #ccc;
							fill: transparent;
						}
						.SvgProgressInternal {
							fill: #ccc;
						}
						.MuiTypography-root {
							color: $menuColor;
							font-size: 14px;
							line-height: 14px;
							font-weight: bold;
						}
						&.Mui-selected {
							background-color: transparent;
							> * > .MuiTypography-root {
								color: $menuHighlight;
							}
							&::before {
								content: " ";
								display: block;
								width: 12px;
								height: 12px;
								background-color: $menuHighlight;
								border-radius: 6px;
								position: absolute;
								left: -4px;
								top: calc(50% - 6px);
							}
						}
						.chevronIcon {
							display: block;
							position: absolute;
							left: -8px;
							top: calc(50% - 12px);
							fill: $menuColor;
							transition: all 0.4s ease;
							&.opened {
								transform: rotateZ(0);
								fill: $menuHighlight;
							}
							&.closed {
								transform: rotateZ(-90deg);
							}
						}
					}
					.MuiCollapse-wrapper.MuiCollapse-vertical {
						background-color: transparent;
						padding: 0 0 0 0;
						margin: 0 0 0 19px;
						border-left: solid 1px $submenuBorderColor;
						width: auto;
					}
					hr.MuiDivider-root {
						display: none;
						margin: 0 8px !important;
						padding: 0 !important;
						border-color: $menuColor;
					}
				}
			}
		}
		main {
			display: block;
			margin-top: 88px;
			min-height: Calc(100vh - 88px);
			padding: 0 0 0 0;
			flex-grow: 1;
			background-color: $bodyColor;
			max-width: 100%;
			.mainContent {
				min-height: Calc(100vh - 88px);
				width: 100%;
				position: relative;
				padding: 1rem 0 0 0;
				> * {
					max-width: 100%;
				}
			}
		}
	}
}

body[theme="DP"] .SiteHeader {
	#appBar {
		.siteLogo {
			background-image: url("../img/dp-platform.png");
		}
	}
}

body[theme="HB"] .SiteHeader {
	#appBar {
		.siteLogo {
			background-image: url("../img/HB.png");
		}
	}
}
