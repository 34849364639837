.darkColor {
  color: #033661;
  fill: #033661;
}

div.titoloSezione > p {
  display: flex;
  margin-bottom: 24px;
  line-height: 28px;
  font-size: 20px;
  color: #033661;
}
div.titoloSezione > p::after {
  display: block;
  content: " ";
  height: 4px;
  border: none;
  background-image: linear-gradient(270deg, rgba(67, 67, 67, 0.6), #033661);
  flex: 1 1 auto;
  margin-left: 10px;
  margin-top: 1rem;
}

div.titoloSezioneCenter > p {
  display: flex;
  margin-bottom: 24px;
  line-height: 20px;
}
div.titoloSezioneCenter > p::before, div.titoloSezioneCenter > p::after {
  display: block;
  content: " ";
  border-style: solid;
  flex: 1 1 auto;
  margin-top: 5px;
}
div.titoloSezioneCenter > p::before {
  border-image: linear-gradient(270deg, rgba(67, 67, 67, 0.6), #033661) 1;
  margin-right: 10px;
}
div.titoloSezioneCenter > p::after {
  border-image: linear-gradient(90deg, rgba(67, 67, 67, 0.6), #033661) 1;
  margin-left: 10px;
}

div.breadCrumbs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 0 0 0.5rem;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  column-gap: 0.25rem;
  /* border-bottom: solid 1px $darkColor; */
  background-color: #ccc;
  background-image: linear-gradient(to top, #ccc, #ddd 1%, #eee 60%, #f6f6f6, #ddd 99%, #ccc);
}
div.breadCrumbs a.link, div.breadCrumbs * {
  color: #666;
  fill: #666;
  font-size: 13px;
}
div.breadCrumbs p {
  flex-shrink: 2;
  margin: 0;
  padding: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.typeStrategy > p {
  display: flex;
  margin-bottom: 24px;
  margin-top: 16px;
  opacity: 0.8;
  margin-left: 8px;
}

.link:hover {
  text-decoration: underline !important;
  color: #033661;
}

.cursorPoint {
  cursor: pointer !important;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.ps__rail-y {
  background-color: transparent !important;
  width: 6px !important;
}
.ps__rail-y:hover {
  background-color: transparent !important;
  width: 6px !important;
}
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 6px !important;
}

.ps__thumb-y {
  right: 0 !important;
}

.buttonUpload {
  height: 53.5px !important;
  margin-top: 8px !important;
  vertical-align: middle !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 6px 6px rgba(36, 93, 139, 0.44);
}

*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0px 1px 6px 2px rgb(1, 27, 48);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.go-edit {
  display: inline-block;
  position: relative;
  transition: all 0.25s ease-out;
}
.go-edit::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.go-edit:hover {
  color: #0087ca;
  transition: all 0.25s ease-out;
}
.go-edit:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

label.boxTitle, .boxField > label {
  color: #033361;
  background-color: #fff;
  padding: 0 0.25rem;
  white-space: nowrap;
}

.boxField > .MuiOutlinedInput-input, .boxField > .MuiOutlinedInput-root {
  min-height: 48px !important;
}

.progress.smallBar {
  width: 120px;
}

.SvgProgressExternal {
  fill: #eee;
}

.SvgProgressInternal {
  fill: #033661;
}

*[class*=weighted] {
  color: #ccc;
}
*[class*=weighted].progress {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2666666667);
  border-radius: 12px;
  height: 12px;
}
*[class*=weighted].progress > .progress-bar {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2666666667), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2666666667));
  height: 12px;
}
*[class*=weighted].weighted-unk {
  color: "#ccc";
  fill: "#ccc";
}
*[class*=weighted].weighted-unk.progress > .progress-bar {
  background-color: "#ccc";
}
*[class*=weighted].weighted-unk .SvgProgressExternal {
  stroke: "#ccc";
  fill: #e6e6e6;
}
*[class*=weighted].weighted-unk .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weighted-unk .SvgProgressInternal {
  fill: "#ccc";
}
*[class*=weighted].weighted-unk.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-unk .SvgProgress.Masked svg + .SvgLabel {
  fill: #033661;
  color: #033661;
}
*[class*=weighted].weighted-unk.SvgBulbs svg, *[class*=weighted].weighted-unk .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weighted-unk.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-unk .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weighted-unk.SvgBulbs .SvgLabel, *[class*=weighted].weighted-unk .SvgBulbs .SvgLabel {
  color: #033661;
  fill: #033661;
}
*[class*=weighted].weighted-unk.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-unk .SvgBulbs .SvgProgressExternal {
  stroke: "#ccc";
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weightedBas, *[class*=weighted].weighted-max {
  color: #009000;
  fill: #009000;
}
*[class*=weighted].weightedBas.progress > .progress-bar, *[class*=weighted].weighted-max.progress > .progress-bar {
  background-color: #009000;
}
*[class*=weighted].weightedBas .SvgProgressExternal, *[class*=weighted].weighted-max .SvgProgressExternal {
  stroke: #009000;
  fill: #e6e6e6;
}
*[class*=weighted].weightedBas .SvgProgressInternal.Off, *[class*=weighted].weighted-max .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weightedBas .SvgProgressInternal, *[class*=weighted].weighted-max .SvgProgressInternal {
  fill: #009000;
}
*[class*=weighted].weightedBas.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weightedBas .SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-max.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-max .SvgProgress.Masked svg + .SvgLabel {
  fill: #033661;
  color: #033661;
}
*[class*=weighted].weightedBas.SvgBulbs svg, *[class*=weighted].weightedBas .SvgBulbs svg, *[class*=weighted].weighted-max.SvgBulbs svg, *[class*=weighted].weighted-max .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weightedBas.SvgBulbs .SvgProgressInternal, *[class*=weighted].weightedBas .SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-max.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-max .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weightedBas.SvgBulbs .SvgLabel, *[class*=weighted].weightedBas .SvgBulbs .SvgLabel, *[class*=weighted].weighted-max.SvgBulbs .SvgLabel, *[class*=weighted].weighted-max .SvgBulbs .SvgLabel {
  color: #033661;
  fill: #033661;
}
*[class*=weighted].weightedBas.SvgBulbs .SvgProgressExternal, *[class*=weighted].weightedBas .SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-max.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-max .SvgBulbs .SvgProgressExternal {
  stroke: #009000;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weighted-high {
  color: #82cb2a;
  fill: #82cb2a;
}
*[class*=weighted].weighted-high.progress > .progress-bar {
  background-color: #82cb2a;
}
*[class*=weighted].weighted-high .SvgProgressExternal {
  stroke: #82cb2a;
  fill: #e6e6e6;
}
*[class*=weighted].weighted-high .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weighted-high .SvgProgressInternal {
  fill: #82cb2a;
}
*[class*=weighted].weighted-high.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-high .SvgProgress.Masked svg + .SvgLabel {
  fill: #033661;
  color: #033661;
}
*[class*=weighted].weighted-high.SvgBulbs svg, *[class*=weighted].weighted-high .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weighted-high.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-high .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weighted-high.SvgBulbs .SvgLabel, *[class*=weighted].weighted-high .SvgBulbs .SvgLabel {
  color: #033661;
  fill: #033661;
}
*[class*=weighted].weighted-high.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-high .SvgBulbs .SvgProgressExternal {
  stroke: #82cb2a;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weightedMed, *[class*=weighted].weighted-medium {
  color: #febf00;
  fill: #febf00;
}
*[class*=weighted].weightedMed.progress > .progress-bar, *[class*=weighted].weighted-medium.progress > .progress-bar {
  background-color: #febf00;
}
*[class*=weighted].weightedMed .SvgProgressExternal, *[class*=weighted].weighted-medium .SvgProgressExternal {
  stroke: #febf00;
  fill: #e6e6e6;
}
*[class*=weighted].weightedMed .SvgProgressInternal.Off, *[class*=weighted].weighted-medium .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weightedMed .SvgProgressInternal, *[class*=weighted].weighted-medium .SvgProgressInternal {
  fill: #febf00;
}
*[class*=weighted].weightedMed.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weightedMed .SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-medium.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-medium .SvgProgress.Masked svg + .SvgLabel {
  fill: #033661;
  color: #033661;
}
*[class*=weighted].weightedMed.SvgBulbs svg, *[class*=weighted].weightedMed .SvgBulbs svg, *[class*=weighted].weighted-medium.SvgBulbs svg, *[class*=weighted].weighted-medium .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weightedMed.SvgBulbs .SvgProgressInternal, *[class*=weighted].weightedMed .SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-medium.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-medium .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weightedMed.SvgBulbs .SvgLabel, *[class*=weighted].weightedMed .SvgBulbs .SvgLabel, *[class*=weighted].weighted-medium.SvgBulbs .SvgLabel, *[class*=weighted].weighted-medium .SvgBulbs .SvgLabel {
  color: #033661;
  fill: #033661;
}
*[class*=weighted].weightedMed.SvgBulbs .SvgProgressExternal, *[class*=weighted].weightedMed .SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-medium.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-medium .SvgBulbs .SvgProgressExternal {
  stroke: #febf00;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weighted-low {
  color: #ff7300;
  fill: #ff7300;
}
*[class*=weighted].weighted-low.progress > .progress-bar {
  background-color: #ff7300;
}
*[class*=weighted].weighted-low .SvgProgressExternal {
  stroke: #ff7300;
  fill: #e6e6e6;
}
*[class*=weighted].weighted-low .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weighted-low .SvgProgressInternal {
  fill: #ff7300;
}
*[class*=weighted].weighted-low.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-low .SvgProgress.Masked svg + .SvgLabel {
  fill: #033661;
  color: #033661;
}
*[class*=weighted].weighted-low.SvgBulbs svg, *[class*=weighted].weighted-low .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weighted-low.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-low .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weighted-low.SvgBulbs .SvgLabel, *[class*=weighted].weighted-low .SvgBulbs .SvgLabel {
  color: #033661;
  fill: #033661;
}
*[class*=weighted].weighted-low.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-low .SvgBulbs .SvgProgressExternal {
  stroke: #ff7300;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weightedEle, *[class*=weighted].weighted-danger {
  color: #a80303;
  fill: #a80303;
}
*[class*=weighted].weightedEle.progress > .progress-bar, *[class*=weighted].weighted-danger.progress > .progress-bar {
  background-color: #a80303;
}
*[class*=weighted].weightedEle .SvgProgressExternal, *[class*=weighted].weighted-danger .SvgProgressExternal {
  stroke: #a80303;
  fill: #e6e6e6;
}
*[class*=weighted].weightedEle .SvgProgressInternal.Off, *[class*=weighted].weighted-danger .SvgProgressInternal.Off {
  stroke: transparent;
  opacity: 0.15;
}
*[class*=weighted].weightedEle .SvgProgressInternal, *[class*=weighted].weighted-danger .SvgProgressInternal {
  fill: #a80303;
}
*[class*=weighted].weightedEle.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weightedEle .SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-danger.SvgProgress.Masked svg + .SvgLabel, *[class*=weighted].weighted-danger .SvgProgress.Masked svg + .SvgLabel {
  fill: #a80303;
  color: #a80303;
}
*[class*=weighted].weightedEle.SvgBulbs svg, *[class*=weighted].weightedEle .SvgBulbs svg, *[class*=weighted].weighted-danger.SvgBulbs svg, *[class*=weighted].weighted-danger .SvgBulbs svg {
  overflow: visible;
}
*[class*=weighted].weightedEle.SvgBulbs .SvgProgressInternal, *[class*=weighted].weightedEle .SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-danger.SvgBulbs .SvgProgressInternal, *[class*=weighted].weighted-danger .SvgBulbs .SvgProgressInternal {
  stroke: transparent;
}
*[class*=weighted].weightedEle.SvgBulbs .SvgLabel, *[class*=weighted].weightedEle .SvgBulbs .SvgLabel, *[class*=weighted].weighted-danger.SvgBulbs .SvgLabel, *[class*=weighted].weighted-danger .SvgBulbs .SvgLabel {
  color: #a80303;
  fill: #a80303;
}
*[class*=weighted].weightedEle.SvgBulbs .SvgProgressExternal, *[class*=weighted].weightedEle .SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-danger.SvgBulbs .SvgProgressExternal, *[class*=weighted].weighted-danger .SvgBulbs .SvgProgressExternal {
  stroke: #a80303;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
*[class*=weighted].weightedEle.SvgBulbs .SvgLabel, *[class*=weighted].weightedEle .SvgBulbs .SvgLabel, *[class*=weighted].weighted-danger.SvgBulbs .SvgLabel, *[class*=weighted].weighted-danger .SvgBulbs .SvgLabel {
  font-weight: bold;
}

.modalDialog .modalTitle {
  border-radius: 4px 4px 0 0;
  padding: 4px 1rem;
  background-color: #033661;
  color: #fff;
}
.modalDialog .modalTitle > svg:first-child {
  opacity: 0.75;
  margin: -4px 8px 0 -8px;
  fill: #ffcd39;
}
.modalDialog .modalTitle button {
  position: absolute;
  top: 0;
  right: 0.25rem;
}
.modalDialog .modalTitle button svg {
  fill: #fff;
}
.modalDialog .modalBody {
  padding: 0.5rem;
}
.modalDialog .modalBody .modalRow {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-end;
  align-content: space-between;
  flex-wrap: nowrap;
}
.modalDialog .modalBody .modalRow .modalRowText {
  flex-grow: 1;
  padding: 0.5rem;
}
.modalDialog .modalBody .modalRow .modalRowText .question {
  font-weight: bold;
}
.modalDialog .modalBody .modalRow .modalRowText .answer {
  font-style: italic;
}
.modalDialog .modalBody .modalRow .modalRowControls {
  padding: 0.5rem;
}
.modalDialog .modalBody .modalRow .modalRowControls .control {
  width: 10rem;
}
.modalDialog .modalBody .modalRow .modalRowControls .control label {
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  color: #999;
  width: 5rem;
  text-align: right;
}
.modalDialog .modalBody .modalRow .modalRowControls .control span {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: bold;
}
.modalDialog .modalBody .modalRow:first-of-type:last-of-type {
  margin: 1rem;
  padding: 0;
}
.modalDialog .modalBody .modalRow + .modalRow {
  margin-top: 0.5rem;
  border-top: 1px solid #ccc;
}
.modalDialog .modalBody .modalRow *[class*=weighted] {
  color: #ccc;
}
.modalDialog .modalBody .modalRow *[class*=weighted].progress {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2666666667);
  border-radius: 12px;
  height: 12px;
}
.modalDialog .modalBody .modalRow *[class*=weighted].progress > .progress-bar {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2666666667), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2666666667));
  height: 12px;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-unk {
  color: #ccc;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-unk.progress > .progress-bar {
  background-color: #ccc;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedBas, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-max {
  color: #009000;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedBas.progress > .progress-bar, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-max.progress > .progress-bar {
  background-color: #009000;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedMed, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-medium {
  color: #febf00;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedMed.progress > .progress-bar, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-medium.progress > .progress-bar {
  background-color: #febf00;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-high {
  color: #82cb2a;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-high.progress > .progress-bar {
  background-color: #82cb2a;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-low {
  color: #ff7300;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weighted-low.progress > .progress-bar {
  background-color: #ff7300;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedEle, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-danger {
  color: #a80303;
}
.modalDialog .modalBody .modalRow *[class*=weighted].weightedEle.progress > .progress-bar, .modalDialog .modalBody .modalRow *[class*=weighted].weighted-danger.progress > .progress-bar {
  background-color: #a80303;
}

