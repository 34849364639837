.SvgPie {
  position: relative;
  display: inline-block;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}
.SvgPie > svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.SvgPie.SvgProgress > .SvgLabel {
  position: absolute;
  left: 0;
  right: 0;
  line-height: 12px;
  font-size: 14px;
  top: calc(50% - 7px);
  font-weight: bold;
  color: #fff;
  text-shadow: -1px 0 2px rgba(0, 0, 0, 0.4), 2px 0 2px rgba(0, 0, 0, 0.4), 0 -1px 2px rgba(0, 0, 0, 0.4), 0 2px 2px rgba(0, 0, 0, 0.4);
  vertical-align: middle;
}
.SvgPie.SvgProgress.Masked > .SvgLabel {
  color: #000;
  transform: scale(0.9);
  font-weight: normal;
  text-shadow: none;
}
.SvgPie.SvgProgress.SizeS > .SvgLabel {
  font-size: 12px;
}
.SvgPie.SvgProgress.SizeXS > .SvgLabel {
  display: none;
}

.SvgBulbs {
  position: relative;
  display: inline-block;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;
}
.SvgBulbs > svg {
  display: block;
  width: 100%;
  margin: 0;
}
.SvgBulbs.SvgProgress > .SvgLabel {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 88%;
}

